<template>
  <BCard>
    <h3 class="text-10 m-0">
      Portofolio
    </h3>
    <p class="text-muted mt-1 mb-[32px]">
      Tambah media seperti gambar, ataupun link sebagai bahan portofolio
    </p>
    <BRow class="gap-[8px]">
      <BCol md="8">
        <BButton
          v-b-modal.modalMedia
          variant="outline-secondary"
          class="border-red"
          :disabled="portfolios.length >= 4"
        >
          + Tambah Media
        </BButton>
      </BCol>
      <BCol md="12">
        <p
          v-if="portfolios.length === 0"
          class="text-center"
        >
          Belum ada portofolio yang tersimpan
        </p>
        <template v-else>
          <div
            v-for="(portfolio, i) in portfolios"
            :key="i"
            class="flex gap-[32px] border-b last:border-b-0 py-[20px]"
          >
            <div class="min-w-[175px] h-[110px] bg-[#f8f8f8] relative border rounded-xl flex justify-center items-center overflow-hidden">
              <span
                v-if="portfolio.file"
                class="k-document-text-1 text-5xl font-bold text-black -rotate-[15deg]"
              />
              <span
                v-else
                class="k-link-21 text-5xl font-bold text-black -rotate-[15deg]"
              />
              <div
                class="flex items-center justify-center absolute right-[5px] bottom-[5px] border bg-white rounded-md h-[32px] w-[40px] cursor-pointer"
                @click="selectPortfolio(portfolio, i)"
              >
                <span
                  class="k-edit-2 text-xl text-black"
                />
              </div>
            </div>
            <div class="flex justify-between w-full">
              <div class="title flex-col">
                <p class="text-8 font-semibold text-[#333333]">
                  {{ portfolio.title }}
                </p>
                <p class="text-7 text-muted">
                  {{ portfolio.description || '-' }}
                </p>
              </div>
              <div class="ml-auto">
                <img
                  v-b-modal.modalDelete
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                  alt="Komerce"
                  class="cursor-pointer ml-auto h-[20px]"
                  @click="selectToDelete(portfolio, i)"
                >
              </div>
            </div>
          </div>
        </template>
      </BCol>
    </BRow>
    <ModalMedia
      :data-selected="dataSelected"
      @addPortfolio="addPortfolio($event)"
      @editPortfolio="editPortfolio($event)"
      @hidden="dataSelected = null, indexSelected = null"
    />
    <ModalDelete
      :loading-submit="loadingSubmit"
      @submit="removePortfolio()"
    />
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import ModalMedia from './ModalMedia.vue'
import ModalDelete from '../ModalDelete.vue'

export default {
  components: {
    ModalMedia,
    ModalDelete,
  },
  props: {
    portfoliosExist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showAddSkills: false,
      portfolios: [],
      listSkills: [],
      skill: null,
      alertError,
      alertSuccess,
      dataSelected: null,
      indexSelected: null,
      loadingSubmit: false,
    }
  },
  watch: {
    portfolios(newVal) {
      this.$emit('update:portfolios', newVal)
    },
    portfoliosExist(newVal) {
      this.portfolios = newVal
    },
  },
  mounted() {
    this.getListSkills()
  },
  methods: {
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=1`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleInput(evt) {
      const { id, name } = evt
      const data = {
        skill_id: id,
        skill_name: name,
      }

      this.skills.push(data)
      this.skill = null
      this.showAddSkills = false
    },
    fileUrl: file => URL.createObjectURL(file),
    addPortfolio(evt) {
      this.portfolios.push(evt)
    },
    editPortfolio(evt) {
      this.portfolios.splice(this.indexSelected, 1, evt)
    },
    removePortfolio() {
      if (this.dataSelected.id) {
        this.deletePortfolio(this.dataSelected.id, this.indexSelected)
      } else {
        this.portfolios.splice(this.indexSelected, 1)
        this.$bvModal.hide('modalDelete')
      }
    },
    async deletePortfolio(id, index) {
      this.loadingSubmit = true
      const url = `/v1/talent_portfolios/${id}/delete`
      await komtimAxiosIns
        .delete(url)
        .then(async response => {
          this.alertSuccess('Berhasil menghapus portfolio')
          this.portfolios.splice(index, 1)
        })
        .catch(error => {
          this.alertError(error)
        })
        .finally(() => {
          this.loadingSubmit = false
          this.dataSelected = null
          this.indexSelected = null
          this.$bvModal.hide('modalDelete')
        })
    },
    selectPortfolio(data, index) {
      this.dataSelected = data
      this.indexSelected = index
      this.$bvModal.show('modalMedia')
    },
    selectToDelete(data, index) {
      this.dataSelected = data
      this.indexSelected = index
    },
  },
}
</script>
